import 'whatwg-fetch';
import * as helpers from './helpers';
import * as constants from './constants';
import * as models from '../models/api';

interface ETagCache {
  [key: string]: string;
}

// Response Codes
export const RESPONSE_CODES = {
  GENERAL_INVALID: '16',
  OUT_OF_WINDOW: '40',
  OVERLIMIT: '21',
  VALID: '20'
};

class Api {
  private storage = this.initializeStorage();
  private defaults: models.IDefaults = { wid: constants.DEFAULT_WID };

  private eTagCache:ETagCache = {};

  constructor() {
    this.getQSPs();
  }

  public getQSPStorage() {
    return this.storage.qsps;
  }

  public fetchFundCms(wid:string = constants.EVENT_WID, sid = ''): Promise<void | null> {

    const sidString = sid ? `/snapshot/${sid}` : '';
    const etag:string = this.eTagCache[wid];
    let headers:HeadersInit = {};

    if(etag) headers['If-None-Match'] = etag;

    return fetch(`${constants.API_CMS}${wid}${sidString}`, { headers })
      .then( (response) => {
        const etag:string = response.headers.get('etag') as string;
        if(etag) this.eTagCache[wid] = etag;
        return response.json();
      })
      .catch(err => {
        return null;
      });
  }

  public postToVoteApi(payload: any, versionId: string): any {
    const qsp = helpers.convertToQspString(payload);
    const hash = helpers.createHash(
      qsp,
      `${constants.VERSION_CHECK}${versionId}`
    );
    const encodedHash = helpers.fixedEncodeUriComponent(hash);
    const url = `${constants.CONNECT_ENDPOINT}?${qsp}`;

    return fetch(url, {
        method: 'POST',
        body: `Authorization=${encodedHash}`,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        }
      })
      .then( res => res.json() )
      .catch( err => console.error('Voteapi call failed:', err.message) );
  }

  private getQSPs(): void {
    window.location.search
      .slice(1)
      .split('&')
      .forEach(item => {
        const [key, value = ''] = item.split('=');

        this.storage.qsps[key] = value;
      });
  }

  /**
   * initializeStorage
   */
  private initializeStorage(): models.IStorage {
    const storage = Object.create(null);
    storage.cms = Object.create(null);
    storage.qsps = Object.create(null);

    return storage;
  }
}

export default new Api();
