// App Settings
export const DEFAULT_WID = '31e040a949f02451';
export const EVENT_WID = '71d070b9f9804457';
export const STYLES_WID = 'f1f0405999d06475';
export const VERSION_CHECK = 'giFEgRlwHSkh7K0GrU';

// Pages
export const VIEWS = {
  EVENT: "event",
  CLOSED: "closed"
};

// Endpoints
export const API_CMS = 'https://widgetstate.votenow.tv/v1/state/';
export const CONNECT_ENDPOINT = 'https://voteapi-uk.votenow.tv/s2/vote';

export const GA_EVENTS = {
  ROUTING_CLICK: {
    category: 'click',
    action: 'button',
  },
  FOOTER_CLICK: {
    category: 'click',
    action: 'link',
    label: 'footer link'
  }
};


// Countdown
export const SECOND = 1;
export const MINUTE = 60;
export const HOUR = 3600;
export const DAY = 86400;
