import * as React from 'react';

export const ArrowDownIcon = ( props: any ) => {
	return (
		<span className="arrow-down-icon">
			<svg version="1.1" id="Layer_1" x="0px" y="0px" width="15px" viewBox="0 0 476.213 476.213" fill={props.color}>
		  		<polygon points="347.5,324.393 253.353,418.541 253.353,0 223.353,0 223.353,419.033 128.713,324.393 107.5,345.607 238.107,476.213 368.713,345.606 "/>
	  		</svg>
    	</span>
	);
};

export const CloseIcon = ( props: any ) => {
	return (
		<span className="close-icon">
			<svg fill={props.color} xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24" width="24px" height="24px"><path d="M 4.7070312 3.2929688 L 3.2929688 4.7070312 L 10.585938 12 L 3.2929688 19.292969 L 4.7070312 20.707031 L 12 13.414062 L 19.292969 20.707031 L 20.707031 19.292969 L 13.414062 12 L 20.707031 4.7070312 L 19.292969 3.2929688 L 12 10.585938 L 4.7070312 3.2929688 z"/></svg>
    	</span>
	);
};
