import React, { useEffect, useState } from 'react';
import useCMS from '@telescope/react-hooks/useCMS';
import { isTrue } from '@telescope/utility-helpers';
import api from './util/api';
import {DEFAULT_WID, EVENT_WID} from './util/constants';

import Closed from './components/closed';
import Event from './components/event';
import pym from 'pym.js';
import styles from './App.module.css';

import { use100vh } from 'react-div-100vh';
import * as constants from "./util/constants";
import { form } from './util/form';

new pym.Child({ polling: 500 });

function App() {
  const [ stylesData, setStylesData ] = useState(null);
  const [ isReady, setIsReady ] = useState(false);
  const [ pollingFrequency, setPollingFrequency ] = useState(15000);
  const height = use100vh();

  const qsps = api.getQSPStorage();
  const [ widget ] = useCMS(qsps.wid || DEFAULT_WID, { sid: qsps.sid, pollingFrequency });
  const [ eventData ] = useCMS(qsps.wid || EVENT_WID, { sid: qsps.event_sid, pollingFrequency: 5000 });

  const appSettings = widget ? widget.settings : {};
  const windowStatus = widget ? parseInt(widget.settings.window_status, 10) : 0;

  useEffect(() => {
    if (widget) {
      if (!isReady) setIsReady(true);
      setPollingFrequency(widget.settings.cms_poll_rate * 1000);
    }
  }, [ widget, isReady ]);

  useEffect(()=>{
    api.fetchFundCms(constants.STYLES_WID, qsps.styles_sid ? qsps.styles_sid : '' ).then(response => {
      if (response && response.snapshot && response.snapshot.data ) {
        setStylesData(response.snapshot.data.text);
      }
    });
  }, []);


  const {
    closed = {},
    header = {},
    klaviyo = {}
  } = widget ? widget.text : {};

  let content;

  if( !isReady ) {
    content = <div> Loading... </div>;
  } else if ( !windowStatus ) {
    content = <Closed copy={ closed } stylesData={ stylesData }/>;
    if (isTrue(klaviyo.is_enabled || '')) {
      form.show();
    }
  } else if (eventData) {
    content = <Event text={eventData?.text.event} />;
  }

  useEffect(() => {
    const bodyHeight = height ? `${height}px` : null;
    document.querySelector('body').style.height = bodyHeight;
  }, [height]);

  return (
    <div className={ styles.app }>

      <header>
        <div className={ styles.header_content }>
          <a href={header.logo_link} target='_blank'>
            <img src={header.logo_src} alt={header.logo_alt}/>
          </a>
        </div>
      </header>

      <div className={ styles.container }>
        { content }
      </div>
    </div>
  );
}

export default App;
