import React, {useEffect, useState} from 'react';
import styles from './closed.module.css';
import {ArrowDownIcon} from "../../util/icons";
import Countdown from "../countdown";
import { trackLoadPage } from '../../util/horizon-gold';
const camelcase = require('camelcase');

function Closed( props ) {

    const { copy, stylesData } = props;
    const [ liveEvent, setLiveEvent ] = useState({});
    const [ aboutEvent, setAboutEvent ] = useState({});
    const [ aboutArtist, setAboutArtist ] = useState({});
    const [ formStyles, setFormStyles ] = useState({});
    const showCountdown = false;

    const setSectionStyles = ( data ) => {
        let styleObject = {};
        Object.keys(data).forEach(key => {
            styleObject[camelcase(key)] = data[key];
        });
        return styleObject;
    };

    useEffect(() => {
        trackLoadPage('close-page');

        if ( stylesData && stylesData.closed ) {
            setLiveEvent(setSectionStyles( stylesData.closed.live_event ));
            setAboutEvent(setSectionStyles( stylesData.closed.about_event ));
            setAboutArtist(setSectionStyles( stylesData.closed.about_artist ));
            setFormStyles(setSectionStyles( stylesData.closed.form ));
        }
    }, [ stylesData ]);

    const scrollToForm = () => {
        document.getElementById('rsvp_form').scrollIntoView({behavior: 'smooth'});
    };

    return (
        <div className={ styles.closed }>
            <button className={ styles.rsvp_scroll_button }
                    onClick={scrollToForm}>{ copy.rsvp_scroll_button }</button>
            <section style={ liveEvent } className={ styles.live_event}>
                <div className={ styles.section_container}>
                    <div>
                        <h1 dangerouslySetInnerHTML={{ __html: copy.live_event.headline }}/>
                        <p dangerouslySetInnerHTML={{ __html: copy.live_event.description }}/>
                        <div className={ styles.rsvp_button }
                             onClick={scrollToForm}>
                            <span dangerouslySetInnerHTML={{ __html: copy.live_event.rsvp_button }}/>
                            <ArrowDownIcon color={stylesData?.closed?.live_event?.color}/>
                        </div>
                    </div>
                    <img className={ styles.section_image}
                         src={copy.live_event.image_src}
                         alt={copy.live_event.image_alt}/>
                </div>
            </section>

            <section style={ aboutEvent } className={ styles.about_event}>
                <div className={styles.section_container}>
                    <div>
                        <img className={ styles.section_logo} alt={copy.about_event.logo_alt} src={copy.about_event.logo_src}/>
                        {showCountdown && <Countdown data={copy.countdown}/>}
                        <h1 dangerouslySetInnerHTML={{ __html: copy.about_event.headline }}/>
                        <p className={ styles.date } dangerouslySetInnerHTML={{ __html: copy.about_event.date }}/>
                        <p className={styles.about_event_description}
                           dangerouslySetInnerHTML={{ __html: copy.about_event.description }}/>
                    </div>
                </div>
            </section>

            <section style={ aboutArtist }>
                <div className={styles.section_container}>
                    <div>
                        <h1 dangerouslySetInnerHTML={{ __html: copy.about_artist.headline }}/>
                        <p dangerouslySetInnerHTML={{ __html: copy.about_artist.description }}/>
                        <img alt={copy.about_artist.logo_alt} height='84px' src={copy.about_artist.logo_src}/>
                    </div>
                    <img className={ styles.section_image }
                         src={copy.about_artist.image_src}
                         alt={copy.about_artist.image_alt}/>
                </div>
            </section>
            <section style={ formStyles } id='rsvp_form'>
                <div className={styles.section_container}>
                    <div>
                        <h1 dangerouslySetInnerHTML={{ __html: copy.form.headline }}/>
                        <p dangerouslySetInnerHTML={{ __html: copy.form.description }}/>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Closed;
