export const trackLoadPage = (pageName) => {
  if (!window || !window.dataLayer) { return }
  
  // Send event to Horizon Gold
  window.dataLayer.push({
    'event' : 'loadPage',
    'eventCategory' : 'loadPage',
    'eventAction' : pageName,
    'eventLabel' : pageName
  });
}

export const trackProductLoad = (productName) => {
  if (!window || !window.dataLayer) { return }
  
  // Send event to Horizon Gold
  window.dataLayer.push({
    'event' : 'loadProductPage',
    'eventCategory' : 'loadProductPage',
    'eventAction' : productName,
    'eventLabel' : productName
  });
}

export const trackCloseOverlay = () => {
  if (!window || !window.dataLayer) { return }
  
  // Send event to Horizon Gold
  window.dataLayer.push({
    'event' : 'closeVideoOverlay',
    'eventCategory' : 'closeVideoOverlay',
    'eventAction' : "closeVideoOverlay",
    'eventLabel' : "closeVideoOverlay"
  });
}

export const trackChatClick = () => {
  if (!window || !window.dataLayer) { return }

  window.dataLayer.push({
    'event': 'customEvent',
    'eventCategory': 'chat',
    'eventAction': 'click',
    'eventLabel': 'join',
    'eventInteraction': 'true'
  });
}

export const trackShopClick = () => {
  if (!window || !window.dataLayer) { return }

  window.dataLayer.push({
    'event': 'customEvent',
    'eventCategory': 'shop',
    'eventAction': 'click',
    'eventLabel': 'opened',
    'eventInteraction': 'true'
  });
}

export const trackBuyNowClick = () => {
  if (!window || !window.dataLayer) { return }

  window.dataLayer.push({
    'event': 'customEvent',
    'eventCategory': 'pop-up',
    'eventAction': 'click',
    'eventLabel': 'buy now',
    'eventInteraction': 'true'
  });
}

export const trackClosePopUpClick = () => {
  if (!window || !window.dataLayer) { return }

  window.dataLayer.push({
    'event': 'customEvent',
    'eventCategory': 'pop-up',
    'eventAction': 'click',
    'eventLabel': 'close',
    'eventInteraction': 'true'
  });
}