import * as React from 'react';
import * as constants from '../../util/constants';
import CountdownUnit from './countdown-unit';
import styles from './countdown.module.css';
import {checkIfTrue} from "../../util/helpers";
import {useEffect, useState} from "react";

function Countdown( props ) {

    let timer;
    const { data } = props;
    const [ countdown, setCountdown ] = useState({
        days: '00',
        hours: '00',
        minutes: '00',
        seconds: '00',
        displayDays: checkIfTrue(data.display.days),
        displayHours: checkIfTrue(data.display.hours),
        displayMinutes: checkIfTrue(data.display.minutes),
        displaySeconds: checkIfTrue(data.display.seconds)
    } );


    const updateCountdown = () => {
        const endDate = new Date(`${data.month_name} ${data.day_number} ${data.year_number} ${data.hours_number}:${data.minutes_number}:${data.seconds_number} ${data.meridiem} ${data.timezone} `)
        const currentDate = new Date();
        const timeDiff = (endDate.getTime() - currentDate.getTime()) / 1000;

        if (isNaN(timeDiff) || timeDiff <= 0) {
            return;
        }

        const time = convertTimeToCountdown(timeDiff);
        setCountdown( { ...countdown, ...time });
        timer = setTimeout(updateCountdown.bind(this), 1000);
    };

    useEffect(() => {
        updateCountdown();
        return () => {
            clearTimeout(timer);
        }
    }, [ timer, data ]);

    const convertTimeToCountdown = (remaining) => {
        let { days, hours, minutes, seconds } = countdown;
        const { displayDays, displayHours, displayMinutes, displaySeconds } = countdown;
        const { DAY, HOUR, MINUTE, SECOND } = constants;

        if (displayDays) {
            days = formatTimeValue(Math.floor(remaining / DAY));
            remaining %= DAY;
        }

        if (displayHours) {
            hours = formatTimeValue(Math.floor(remaining / HOUR));
            remaining %= HOUR;
        }

        if (displayMinutes) {
            minutes = formatTimeValue(Math.floor(remaining / MINUTE));
            remaining %= MINUTE;
        }

        if (displaySeconds) {
            seconds = formatTimeValue(Math.floor(remaining / SECOND));
        }

        return { days, hours, minutes, seconds };

    };

    const formatTimeValue = (val) => {
        const str = val.toString();
        const shouldModify = str.length < 2;

        return shouldModify ? (`00${str}`).slice(-2) : str;
    }

    return (
        <div className={styles.countdown_container}>
            <p className={styles.description}
               dangerouslySetInnerHTML={{ __html: data.description }}/>

            <div className={styles.timer_wrapper} aria-live='assertive' aria-atomic='true'>
                {countdown.displayDays &&
                    <CountdownUnit styles={styles} number={countdown.days} displayLabel={data.display.labels}
                                   labels={data.labels.days}/>}

                {countdown.displayDays && countdown.displayHours &&
                    <span className={styles.colon}> : </span>}

                {countdown.displayHours &&
                    <CountdownUnit styles={styles} number={countdown.hours} displayLabel={data.display.labels}
                                   labels={data.labels.hours}/>}

                {countdown.displayMinutes &&
                    <span className={styles.colon}> : </span>}

                {countdown.displayMinutes &&
                    <CountdownUnit styles={styles} number={countdown.minutes} displayLabel={data.display.labels}
                                   labels={data.labels.minutes}/>}

                {countdown.displaySeconds &&
                    <span className={styles.colon}> : </span>}

                {countdown.displaySeconds &&
                    <CountdownUnit styles={styles} number={countdown.seconds} displayLabel={data.display.labels}
                                   labels={data.labels.seconds} ariaHidden={true}/>}
            </div>

        </div>
    );
}

export default Countdown;
