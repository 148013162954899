import BASE64 from 'crypto-js/enc-base64';
import HMACSHA256 from 'crypto-js/hmac-sha256';
import HMACSHA1 from 'crypto-js/hmac-sha1';

export const convertToQspString = (params: any) => {
  let result = '';
  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      const value = params[key];
      if (value) {
        const encodedValue = fixedEncodeUriComponent(String(value));
        result += `${key}=${encodedValue}&`;
      }
    }
  }
  return result.slice(0, -1);
};

export const createSha1Hash = (qsp: string, secret: string) => {
  const hash = HMACSHA1(qsp, secret);
  return BASE64.stringify(hash);
};

export const createHash = (qsp: string, secret: string) => {
  const hash = HMACSHA256(qsp, secret);
  return BASE64.stringify(hash);
};

export const fixedEncodeUriComponent = (str: string) => {
  return encodeURIComponent(str).replace(/[!'()*]/g, function(c) {
    return '%' + c.charCodeAt(0).toString(16);
  });
};

export const checkIfTrue = (value: string) => {
  return ['true', '1', true, 1, 'yes'].indexOf(value) >= 0;
};

export const createCookie = (name: string, value: string, days = 50 * 365) => {
  var expires = "";

  if ( days ) {
      var date = new Date();
      date.setTime( date.getTime() + (days*24*60*60*1000) );
      expires = "; expires=" + date.toUTCString();
  }

  document.cookie = name + "=" + value + expires + "; path=/";
}

export const readCookie = (name: string) => {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');

  for ( var i = 0; i < ca.length; i++ ) {
      var c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }

  return null;
}

export const eraseCookie = (name: string) => {
  createCookie( name, "", -1 );
}