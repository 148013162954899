import React, {useEffect, useState, useRef} from 'react';
import {
    trackBuyNowClick,
    trackChatClick,
    trackCloseOverlay,
    trackClosePopUpClick,
    trackLoadPage,
    trackProductLoad,
    trackShopClick
} from '../../util/horizon-gold';
import styles from './event.module.css';
import {CloseIcon} from "../../util/icons";

function Event(props) {
    const {text} = props;
    const shopContainer = useRef();
    const [isIframeVisible, setIframeVisibility] = useState(false);
    const [activeIframeView, setActiveIframeView] = useState();
    const [lastVisitedShop, setLastVisitedShop] = useState();
    const [showProductOverlay, toggleProductOverlayVisibility] = useState(true);

    useEffect(() => {trackLoadPage('event-page')}, []);

    useEffect(() => {
        toggleProductOverlayVisibility(text?.highlighted_product?.show);
        setLastVisitedShop(text?.iframe.shop.default_shop_url);
        setActiveIframeView(text?.iframe.default_view);
        setIframeVisibility(text?.iframe.default_visible);
    }, [text]);

    const showProduct = (link, name) => {
        setLastVisitedShop(link);
        setActiveIframeView("shop");
        setIframeVisibility(true);
        
        window.scrollTo(0, shopContainer.current.offsetTop);
        trackProductLoad(name);
    }

    const closeOverlay = () => {
        toggleProductOverlayVisibility(false);
        trackCloseOverlay();
        trackClosePopUpClick();
    }

    const triggerOverlayAction = (product) => {
        trackBuyNowClick();
        showProduct(product);
    }

    const getProductOverlay = (product) => (
        <div className={styles.product_overlay}>
            <img src={product.image} alt={product.alt} />
            <div className={ styles.product_data }>
                <span>{product.title}</span>
                <button className={ styles.buy_button}
                        onClick={() => triggerOverlayAction(product.url, product.title)}>{product.button_text}</button>
            </div>
            <button className={ styles.close_button } onClick={closeOverlay}><CloseIcon/></button>
        </div>
    );

    const getProductGrid = (products) => {
        return (
            <ul>
                {products?.map((product, index) => <li key={index} onClick={() => showProduct(product.link, product.title)}>
                    <img src={product.image} width='100%' />
                </li>)}
            </ul>
        )
    };

    const switchIframeView = (destination) => {
        if (destination === "chat") {
            trackChatClick();
        } else {
            trackShopClick();
        }
        setActiveIframeView(destination);
    }

    return (
        <section className={styles.container}>
            <h1 dangerouslySetInnerHTML={{ __html: text?.headline }}/>
            <h2 dangerouslySetInnerHTML={{ __html: text?.subheadline }}/>
            <div className={styles.iframes_container}>
                <div className={ styles.video_container }>
                    {showProductOverlay && text?.highlighted_product
                        && text.highlighted_product.show && getProductOverlay(text?.highlighted_product)}
                    <iframe src={text?.video_url} title="embed_video" className={styles.video}
                            allowFullScreen="allowfullscreen"
                            mozallowfullscreen="mozallowfullscreen"
                            msallowfullscreen="msallowfullscreen"
                            webkitallowfullscreen="webkitallowfullscreen"/>
                </div>
                <p className={ styles.availability_mobile }
                    dangerouslySetInnerHTML={{ __html: text?.availability }}/>
                <ul className={styles.nav_items_mobile}>
                    <li className={ activeIframeView === 'chat' ? styles.nav_active : undefined}
                        onClick={() => setActiveIframeView('chat')}>Chat</li>
                    <li className={ activeIframeView === 'shop' ? styles.nav_active : undefined}
                        onClick={() => setActiveIframeView('shop')}>Shop</li>
                </ul>
                <div ref={shopContainer} className={ styles.chat_container}>
                    { isIframeVisible && text &&
                        <>
                            <iframe className={ activeIframeView === 'chat' ? styles.show_iframe : undefined}
                                    src={text.iframe.chat.url} title='embed_chat'/>
                            <iframe className={ activeIframeView === 'shop' ? styles.show_iframe : undefined}
                                    src={lastVisitedShop} title='embed_shop'/>
                        </>
                    }
                </div>
            </div>
            <ul className={styles.nav_items_desktop}>
                <li className={ activeIframeView === 'chat' ? styles.nav_active : undefined}
                    onClick={() => switchIframeView('chat')}>Chat</li>
                <li className={ activeIframeView === 'shop' ? styles.nav_active : undefined}
                    onClick={() => switchIframeView('shop')}>Shop</li>
            </ul>
            <p className={ styles.availability }
               dangerouslySetInnerHTML={{ __html: text?.availability }}/>
            <div className={styles.product_grid}>
                {getProductGrid(text?.products)}
            </div>
        </section>
    );
}

export default Event;
