
class Form {
    reference: HTMLElement | { style: { display: string }};

    constructor() {
        this.reference = { style: { display: '' } };
        const domForm = document.getElementById('klaviyo-container');
        if (domForm) {
            this.reference = domForm;
        }
    }

    show() {
        this.reference.style.display = 'block';
    }

    hide() {
        this.reference.style.display = 'none';
    }
}

export const form = new Form();